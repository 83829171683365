* {
  font-family: 'WebErpFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'WebErpFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.displayNone {
  display: none !important;
}

.pointer {
  cursor: pointer;
}
.emoji-mart-preview {
  display: none;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* FIXME: cuurently i couldnt find this className so i override it here! */
.MuiCollapse-entered {
  overflow: auto !important;
}

/* TODO:
   find a better way to override menu class of react-select 
   that is currently used in tag-input.view.tsx
   
   this override fixes the problem of selecting
   resultlist when menu isOpen cause other inputs have 
   z-index: 1, so that menu should has z-index value 
   more than that
*/

.css-26l3qy-menu {
  z-index: 2 !important;
}

.chat-message-highlight {
  animation: Chat-Message-Highlighter 5s linear;
  border-radius: 4px;
}

@keyframes Chat-Message-Highlighter {
  from {
    background: rgba(0, 0, 0, 0.06);
  }
  to {
    background: none;
  }
}

/* CkEditor ballon panel issue in modal, dialogs, ... */
.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 10055 !important;
}

