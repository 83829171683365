/* DatePicker class name */
.tether-element {
  z-index: 1300;
}

.datePickerContainer {
  background-color: #ffffff;
  width: 100%;
  border: 1px solid;
  border-color: #bdbdbd;
  border-radius: 4px;
  padding: 3px;
  margin: 0;
  height: 100%;
  position: relative;
  min-height: 40px;
}

.filterDatePickerContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.filterDatePickerContainer input {
  border: none;
  text-align: center;
  width: 100%;
}

.datePickerContainer:hover {
  border: 1px solid;
}

.datePickerContainer[data-label='false'] {
  padding: 7px;
  height: 36px;
}

.datePickerContainer[disabled] {
  background-color: #e0e0e0;
}

.datePickerContainer[data-puzzle-form='true'] {
  margin: 7px 3px 0;
  padding: 7px 3px 0;
}

.datePickerContainer input {
  border: none;
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
}

.datePickerContainer[data-error='true'] {
  border: 1px solid #f44336;
}

.datePickerContainer[data-error='true'] legend {
  /* font-size: 12px; */
  color: #f44336;
}

.datePickerContainer[data-error='true'] input {
  border: none;
  flex-grow: 1;
  width: 100%;
}

.datePickerContainer input {
  outline: none;
}

.filterDatePickerContainer input {
  outline: none;
}

@media only screen and (min-width: 0px) {
  /* xs */
  .datePickerContainer > div {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .datePickerContainer {
    flex-direction: row !important;
    padding: 3px 14px !important;
  }
  /* down sm */
  .datePickerContainer {
    min-height: 30px;
  }
}

@media only screen and (min-width: 600px) {
  .datePickerContainer {
    flex-direction: row !important;
    padding: 3px 14px !important;
    flex-wrap: nowrap !important;
  }
  /* sm */
  .datePickerContainer > div {
    width: 100%;
  }
}

@media only screen and (min-width: 960px) {
  /* md */
  .datePickerContainer > div {
    width: 100%;
    height: 100%;
  }
}

/* related to RCT-710 */
@media only screen and (max-width: 500px) {
  /* md */
  .calendarContainer {
    zoom: 0.6;
  }
}
