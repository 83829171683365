.rbc-selected-cell {
  background-color: #c7e1f280 !important;
}
.rbc-header + .rbc-header {
  border-left: unset !important;
}

.rbc-off-range {
  color: unset !important;
}

.rbc-event-content {
  display: flex !important;
  direction: rtl;
}
