.react-grid-item.react-grid-placeholder {
  user-select: none;
  border: 2px dashed gray;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  background-color: transparent;
}
.react-grid-layout {
  direction: ltr;
  height: 100% !important;
}
