.flex {
  display: flex;
  flex-direction: column;
}

.wrapper {
  height: 100%;
  background: #fafafa;
  position: absolute;
  top: 0;
  margin-right: 0.1rem;
  border-radius: 2px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 9999;
}

.wrapper::-webkit-scrollbar {
  width: 3px;
  scrollbar-width: 3px;
}

.wrapper ul {
  list-style-type: none;
  padding: 0.5rem 1.2rem 0 6rem;
}
.wrapper li a {
  list-style: none;
  color: #000;
  opacity: 0.8;
}

.topNavHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.8rem 2rem;
}

.topHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;
}

.topHeaderIcon:hover {
  opacity: 0.7;
}

.mega-items-list-module {
  max-height: 90%;
  max-width: 90%;
  display: flex;
  flex-flow: column wrap;
  overflow-x: scroll;
  margin: 0 1.2rem;
}
.mega-items-list-module::-webkit-scrollbar {
  display: none;
}

.mega-items-list {
  max-height: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
}

.mega-items-list::-webkit-scrollbar {
  display: none;
}

.itemsWrapper {
  margin: 0 0 0 1.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  max-height: 50rem;
  padding: 1rem;
}

.mega-menu-list-item-top-header {
  margin: 2rem 0 0.5rem 0;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s;
}

.mega-menu-list-item-header {
  margin: 2rem 1.8rem 0 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.mega-menu-list-item-sub-header {
  margin: 0.5rem 2.8rem 0 0.5rem;
  padding: 0.3rem 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
}

.mega-menu-list-item-label {
  padding: 0.2rem;
  margin: 0.5rem 5.5rem 0 0.5rem;
  font-size: 13px;
  font-weight: 400;
  transition: all 0.2s;
  width: 150px;
  opacity: 0.8;
  cursor: pointer;
}

.mega-menu-list-item-label:hover {
  opacity: 1;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
}

.itemContainer:hover .starBorder {
  transition: all 0.7s ease;
  opacity: 1;
}

.starIcon {
  cursor: pointer;
}

.starBorder {
  transition: all 0.7s ease;
  opacity: 0;
}
