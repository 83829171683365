.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.toggle-text {
  font-size: inherit;
}

.icon {
  font-size: 15px;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;
  transform: rotateZ(180deg);
}

.icon-expanded {
  transform: rotateZ(90deg);
}

.icon-expanded-en {
  transform: rotateZ(-90deg);
}
