.toggle-text {
  font-size: inherit;
}

.icon {
  font-size: 15px;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;
}

.icon-expanded {
  transform: rotateZ(180deg);
}
