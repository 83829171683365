.legend-container {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  width: 200px;
  font-size: 10px;
}

.legend-item {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  /* justify-content: ; */
  width: 100%;
}

.legend-marker {
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}

.legend-icon-mapbox {
  display: flex;
  width: 10%;
  justify-content: space-between;
  align-items: center;
  margin-inline: 8px;
}
.marker {
  width: 15px;
}
