$greycolor: #777;
$selectedColor: #4285f4; //337ab7
$hoverColor: #d6f1ff;

.calendarContainer {
  position: relative;
  border-radius: 4px;
  -moz-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  width: 340px;
  margin: auto;
  text-align: center;
  padding: 15px;
  background-color: #fff;
}

.calendarContainer * {
  box-sizing: border-box;
}

.calendarContainer .dayPickerContainer:after,
.calendarContainer .monthsList:after,
.calendarContainer .daysOfWeek:after {
  content: '';
  display: block;
  clear: both;
}

.datepicker-input {
  box-sizing: border-box;
}

/* Heading */

.calendarContainer .heading {
  height: auto;
  font-weight: bold;
  margin-bottom: 10px;
}

.calendarContainer .heading > button {
  background: none;
  margin: 5px 0;
  border: none;
  text-align: center;
  line-height: 30px;
  width: 36px;
  height: 32px;
  cursor: pointer;
}

.calendarContainer .heading > button:hover {
  background-color: #f2f2f2;
}

.calendarContainer .heading svg {
  width: 10px;
  fill: $greycolor;
}

.calendarContainer .heading .prev,
.calendarContainer .heading .next {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin: 0;
}

.calendarContainer .heading .prev {
  float: right;
}

.calendarContainer .heading .next {
  float: left;
}

.calendarContainer .heading .title {
  height: auto;
  border-radius: 4px;
  width: auto;
  border: 1px solid #f7f7f7;
  text-align: center;
  display: inline-block;
  font-weight: normal;
  padding: 4px 15px 5px 15px;
  line-height: 1.5;
  font-size: 1.2em;
  max-height: none;
}

.jalaali.calendarContainer .heading .title {
  padding: 4px 15px 7px 15px;
}

/* Day wrapper styles */

.calendarContainer .dayWrapper {
  float: left;
  width: 14.28571429%;
  margin-top: 5px;
  position: relative;
}

/* Day wrapper button styles */
.calendarContainer .dayWrapper button {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.1em;
  padding: 0;
  line-height: 1.5;
  padding: 0px 0px 1px 0px;
}

.jalaali.calendarContainer .dayWrapper button {
  padding: 0px 0px 1px 0px;
}

.calendarContainer .dayWrapper:not(.selected) button:hover {
  background-color: $hoverColor;
}

.calendarContainer .dayWrapper button[disabled] {
  color: #aaa;
  cursor: not-allowed;
  background-color: #ebebeb;
}

.calendarContainer .dayWrapper button.selected {
  background-color: #337ab7;
  color: #ffffff;
}

.calendarContainer .dayWrapper:not(.currentMonth) button {
  opacity: 0.5;
}

/* Days of week row */

.calendarContainer .daysOfWeek {
  margin-bottom: 5px;
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  color: lighten($greycolor, 10%);
  direction: rtl;
}

.calendarContainer .daysOfWeek > div {
  flex-grow: 1;
  justify-content: space-between;
  width: 42px;
}

/* Month selector portion */

.calendarContainer .monthsList {
  clear: both;
  width: 100%;
}

.calendarContainer .monthsList button {
  width: 33.33333332%;
  height: 25%;
  float: right;
  border: 1px solid #f9f9f9;
  outline: none;
  font-size: 1em;
  background: #fff;
  padding: 10px 0;
  cursor: pointer;
}

.calendarContainer .monthsList button:hover {
  background: #eeeeee;
  cursor: pointer;
}

/* Year selector portion */

.calendarContainer .yearsList {
  clear: both;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}

.calendarContainer .yearsList button {
  width: 20%;
  height: 25%;
  float: right;
  border: 1px solid #f9f9f9;
  outline: none;
  font-size: 1em;
  background: #fff;
  padding: 10px 0;
  cursor: pointer;
}

.calendarContainer .yearsList button:hover {
  background: #eeeeee;
  cursor: pointer;
}

/* Selected state of buttons */

.calendarContainer .selected button,
.calendarContainer .selected button:hover :not([disabled]),
.calendarContainer .selected button:active,
.calendarContainer .selected button:focus {
  background-color: $selectedColor;
  color: #ffffff;
}

.calendarContainer.jalaali {
  direction: rtl;
}

.calendarContainer.jalaali .dayWrapper {
  float: right;
}

/*time picker style*/

.time-picker-container {
  margin-bottom: 10;
}

.time-picker-container > .time-label {
  float: left;
  line-height: 30px;
  width: 50%;
  text-align: center;
}

.time-picker-container > .time-picker-panel {
  float: right;
  width: 50%;
}

.time-picker-container.jalaali > .time-label {
  float: right;
}

.time-picker-container.jalaali > .time-picker-panel {
  float: left;
}

.rc-time-picker {
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  width: 110px;
  border: 1px solid #f7f7f7;
  font-size: 1.2em;
}

.rc-time-picker * {
  box-sizing: border-box;
}

.rc-time-picker-input {
  margin: 4px 0;
  padding: 0px 15px 1px 15px;
  direction: ltr;
  text-align: center;
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.5;

  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.jalaali.calendarContainer .rc-time-picker-input {
  padding: 0px 15px 3px 15px;
}

.rc-time-picker-input:focus {
  box-shadow: none;
  border: none;
  background-color: #f2f2f2;
}

.rc-time-picker:hover {
  background-color: #f2f2f2;
}

.rc-time-picker-panel {
  z-index: 2001;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}

.rc-time-picker-panel * {
  box-sizing: border-box;
}

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: default;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
  padding: 4px 0;
  font-size: 1.4em;
  text-align: center;
  font-family: inherit;
}

.rc-time-picker-panel-input,
.rc-time-picker-panel-input:hover {
  box-shadow: none;
  border: none;
}

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}

.rc-time-picker-panel-input-invalid {
  border-color: red;
}

.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;
}

.rc-time-picker-panel-clear-btn:after {
  content: 'x';
  font-size: 12px;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease;
}

.rc-time-picker-panel-clear-btn:hover:after {
  color: #666;
}

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
  border-right: 0;
}

.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 144px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.rc-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  user-select: none;
}

.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}

li.rc-time-picker-panel-select-option-selected {
  background: #edfaff;
  color: #2db7f5;
}

li.rc-time-picker-panel-select-option-disabled {
  color: #bfbfbf;
}

li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}

.tether-element.tether-element-attached-top.tether-element-attached-center.tether-target-attached-bottom.tether-target-attached-center.tether-enabled {
  z-index: 2000;
}

.calendarContainer *,
.datepicker-input {
  font-family: inherit;
}

.today {
  button {
    border: 3px solid #4285f4 !important;
  }
}

.jalaali.calendarContainer {
  .selectToday {
    padding: 4px 0 6px 0;
  }
}

.calendarButton {
  display: block;
  width: 100%;
  background: #4285f4;
  color: #fff;
  outline: none;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  padding: 5px 0 7px 0;
  transition: 0.2s all ease-in-out;
  transition-property: background;

  &:hover {
    background: darken(#4285f4, 10);
  }
}

.toggleButton {
  margin-bottom: 1rem;
}

.selectToday {
  margin-top: 1rem;
}

.highLightDot-container {
  text-align: center;
  bottom: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
  direction: ltr;

  .highLightDot {
    &:not(:first-child) {
      margin-left: 2px;
    }

    border: 1px solid #fff;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}

.disabled {
  cursor: not-allowed;
}

button[disabled],
button[disabled]:hover {
  color: #aaa;
  cursor: not-allowed;
  background-color: #ebebeb;
}
