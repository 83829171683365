/* DatePicker class name */
.tether-element {
  z-index: 1300;
}

.datePickerContainer {
  background-color: #ffffff;
  width: 100%;
  border: 1px solid;
  border-color: #bdbdbd;
  border-radius: 4px;
  padding: 8px;
  margin: 0;
  height: 100%;
  position: relative;
  min-height: 40px;
  max-height: 40px;
}

.filterDatePickerContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.filterDatePickerContainer input {
  border: none;
  text-align: center;
  width: 100%;
}

.datePickerContainer:hover {
  border: 1px solid;
}

.datePickerContainer[disabled]:hover {
  border-color: #bdbdbd;
}

.datePickerContainer[data-label='false'] {
  padding: 7px;
  height: 36px;
}

.datePickerContainer[disabled] {
  background-color: #e0e0e0;
}

.datePickerContainer[data-puzzle-form='true'] {
  margin: 7px 3px 0;
  padding: 7px 3px 0;
}

.datePickerContainer input {
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
}

.datePickerContainer[disabled] legend,
.datePickerContainer[disabled] input {
  color: #646464;
}

.datePickerContainer[data-error='warningDatePicker'] {
  border: 1px solid #f3a200;
}

.datePickerContainer[data-error='warningDatePicker'] legend {
  color: #f3a200;
}

.datePickerContainer[data-error='infoDatePicker'] {
  border: 1px solid #0078d4;
}

.datePickerContainer[data-error='infoDatePicker'] legend {
  color: #0078d4;
}

.datePickerContainer[data-error='successDatePickerContainer'] {
  border: 1px solid #34a853;
}

.datePickerContainer[data-error='successDatePickerContainer'] legend {
  color: #34a853;
}

.datePickerContainer[data-error='errorDatePickerContainer'] {
  border: 1px solid #ff5350;
}

.datePickerContainer[data-error='errorDatePickerContainer'] legend {
  color: #ff5350;
}

.datePickerContainer[data-error='noError'] input {
  border: none;
  flex-grow: 1;
  width: 100%;
}

.filterDatePickerContainer input {
  outline: none;
}

.datePickerContainer[data-puzzle-form='true'] {
  padding: 0 10px !important;
}

/* md */
.datePickerContainer[data-puzzle-form='true'],
.datePickerContainer[data-puzzle-form='true'] > div {
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 0px) {
  /* xs */
  .datePickerContainer[data-puzzle-form='true'] {
    width: 100%;
  }

  .datePickerContainer[data-puzzle-form='true'] input::placeholder,
  .datePickerContainer[data-puzzle-form='true'] input::-webkit-input-placeholder,
  .datePickerContainer[data-puzzle-form='true'] input::-moz-placeholder {
    font-size: 0.72rem;
  }
}

@media only screen and (max-width: 600px) {
  /* down sm */
  .datePickerContainer[data-puzzle-form='true'] {
    min-height: 30px;
  }
}

/* related to RCT-710 */
@media only screen and (max-width: 500px) {
  /* md */
  .calendarContainer {
    zoom: 0.6;
  }
}

@media only screen and (min-width: 600px) {
  .datePickerContainer[data-puzzle-form='true'] input::placeholder,
  .datePickerContainer[data-puzzle-form='true'] input::-webkit-input-placeholder,
  .datePickerContainer[data-puzzle-form='true'] input::-moz-placeholder {
    font-size: 0.74rem;
  }
}

@media only screen and (min-width: 960px) {
  .datePickerContainer[data-puzzle-form='true'] input::placeholder,
  .datePickerContainer[data-puzzle-form='true'] input::-webkit-input-placeholder,
  .datePickerContainer[data-puzzle-form='true'] input::-moz-placeholder {
    font-size: 1rem;
    color: #8b8b8b;
  }
}
